.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .wrapperClass {
    display: flex;
    justify-content: center;
  }
  .loadingMessage {
    font-size: 28px;
    font-weight: 400;
    margin: 30px 0px;
    color: #616161;
  }
}
