@import "variables";
* {
  font-family: "Inter", Arial, sans-serif;
}
body {
  min-height: 100vh;
  background-color: $background-primary;
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
