@import "antd/dist/reset.css";
@import "variables";

.App {
  .font-bold {
    font-weight: bold;
  }
  .form-wrapper {
    height: 100%;
    display: grid;
    place-items: center;
    .form {
      width: 500px;
    }
  }
}
