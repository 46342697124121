$background-primary: #ffffff;

$dark-green-primary: #004750;

$light-green: rgb(0, 230, 150);

$box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

$white: rgb(255, 255, 255);

$anomaly-bold-text: #842029;

$anomaly-text: #90323a;

$anomaly-red-background: #fcdbd5;

$light-gray-border: 1px solid #d8d8d8;
