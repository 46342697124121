@import "/src/variables";

.formItemCustom {
  :global(> .ant-row.ant-form-item-row) {
    justify-content: center;
  }
}
.wrapper {
  height: 100%;
  flex-direction: column;
  gap: 10px;
  .statusWrapper {
    background: radial-gradient(
      50% 109137.91% at 50% 50%,
      rgba(233, 30, 99, 0.1) 0%,
      rgba(254, 244, 247, 0) 100%
    );
    text-align: center;
    width: 100%;
    .status {
      color: red;
      font-size: 24px;
      padding: 0 16px;
      background: $white;
      font-weight: 700;
    }
  }
  h1 {
    font-size: 48px;
    font-weight: 700;
    margin: 0.2em 0px;
  }
  h4 {
    font-size: 28px;
    font-weight: 400;
    margin: 0.2em 0px;
    color: #616161;
  }
  .button {
    cursor: pointer;
    width: fit-content;
    border: 1px solid $dark-green-primary;
    padding: 12px;
    background-color: $white;
    display: flex;
    align-items: center;
    font-weight: 600;
    gap: 10px;
    &:hover,
    &:focus {
      background-color: $dark-green-primary;
      color: $white;
    }
  }
}
