.appWrapper{
  display: flex;
  height: 100vh;
  flex-direction: column;
  .body {
    padding: 0 25px;
    flex-grow: 1;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
}
